<template>
  <div
    v-if="!loading"
    class="app-content content "
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-6">
              <div class="avatar avatar-xl avatar-custom float-start">
                <img
                  :src="icon_path ? icon_path : require('@/assets/images/avatars/user_default.png')"
                  alt="avatar"
                >
              </div>
              <div class="content-header-title  mb-0 float-start">
                <h2> {{ user.name }}
                  <template v-if="user.current_institution">
                    <span>{{ user.current_institution.code }}</span>
                    <!-- <span>{{ user.areas && user.areas[0] ? user.areas[0].code : '' }}</span> -->
                    <span>{{ user.current_institution.pivot && user.current_institution.pivot.department_aux ? user.current_institution.pivot.department_aux.name : '' }}</span>
                  </template>
                </h2>
                <span>{{ user.areas ? `${user.areas.name}` : '' }}</span>
                <!-- only admin -->
                <p
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mt-1"
                >
                  <span
                    v-if="user.active"
                    class="badge bg-light-success"
                  >Active</span>
                  <span
                    v-if="!user.active"
                    class="badge bg-light-danger"
                  >Inactive</span>
                </p>
              </div>
            </div>
            <ResearcherNow />
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div
              v-if="!loggedUser.roles.includes('collaborator')"
              class="btn-group"
            >
              <b-dropdown
                variant="button"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <button
                    id="dropdownMenuButton"
                    class="btn btn-primary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Quick actions <i data-feather="chevron-down" />
                  </button>
                </template>
                <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                  <template v-if="loggedUser.roles.includes('super-admin')">
                    <h6 class="dropdown-header">
                      Admin
                    </h6>
                    <b-dropdown-item @click="openFileInput">
                      <a
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasEditPhoto"
                        aria-controls="offcanvasEditPhoto"
                      >Edit photo</a>
                    </b-dropdown-item>
                    <div class="dropdown-divider" />
                  </template>
                  <b-dropdown-item @click="$router.push({name: 'researcher.personal-data.index'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#editCV"
                      aria-controls="editCV"
                    >Edit CV</a>
                  </b-dropdown-item>
                  <b-dropdown-item @click="$router.push({name: 'researcher.scientific-production-report.index'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#scientficProductionReport"
                      aria-controls="scientficProductionReport"
                    >Scientific Production Report</a>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="user.CVFile.length > 0 && !hide"
                    @click="viewCV"
                  >
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#abridgedCV"
                      aria-controls="abridgedCV"
                    >View abridged CV</a>
                  </b-dropdown-item>
                  <div class="dropdown-divider" />
                  <b-dropdown-item @click="$router.push({name: 'researcher.edit-access'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#editCV"
                      aria-controls="editCV"
                    >Edit intranet access</a>
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </div>
            <span
              class="dis-ib"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Table settings"
            >
              <a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                @click="openTableSettings()"
              >
                <i data-feather="settings" />
              </a>
            </span>
            <div class="d-flex mt-1 justify-content-sm-end buttons-print">
              <a
                class="me-2 text-dark"
                @click="toggleAllSections(true)"
              ><i data-feather="maximize-2" /> Open all sections</a>
              <a
                class="text-dark"
                @click="toggleAllSections(false)"
              ><i data-feather="minimize-2" /> Close all sections</a>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <input
              ref="fileInput"
              type="file"
              style="display: none"
              @change="handleFileChange"
            >
            <template
              v-for="field, index in fields"
            >
              <ShortBio
                v-if="field.checked && field.name === 'Short biography' && !loggedUser.roles.includes('collaborator')"
                :key="index"
                :user="user"
              />

              <ContactInformation
                v-if="field.checked && field.name === 'Contact information' && !loggedUser.roles.includes('collaborator')"
                :key="index"
                :user="user"
              />
              <!-- <CalendarAndAlarms
                v-if="field.checked && field.name === 'Calendar' && !loggedUser.roles.includes('collaborator')"
                :key="index"
                @showAlert="showAlert"
              />

              <Tasks
                v-if="field.checked && field.name === 'Calendar' && !loggedUser.roles.includes('collaborator')"
                :key="index"
              /> -->

              <!-- <GeneralNotifications
                v-if="field.checked && field.name === 'General notifications' && !loggedUser.roles.includes('collaborator')"
                :key="index"
                @showAlert="showAlert"
              />

              <OtherNotifications
                v-if="field.checked && field.name === 'Other notifications' && !loggedUser.roles.includes('collaborator')"
                :key="index"
                @showAlert="showAlert"
              />

              <PendingAuthNotifications
                v-if="field.checked && field.name === 'Notifications pending of authorization' && !loggedUser.roles.includes('collaborator')"
                :key="index"
                @showAlert="showAlert"
              />

              <OSHNotifications
                v-if="field.checked && field.name === 'OSH notifications' && !loggedUser.roles.includes('collaborator')"
                :key="index"
                @showAlert="showAlert"
              /> -->

              <!-- <Notifications v-if="!hide" /> -->

              <!-- <Stats
                v-if="!loading"
                :user-id="user.id"
                :year-between="yearBetween"
                :show-all-stats="false"
                :col-size="6"
                :set-default-toggled="false"
              /> -->

              <Timeline
                v-if="field.checked && field.name === 'Timeline' && !loggedUser.roles.includes('collaborator')"
                :key="index"
              />

              <Trips
                v-if="field.checked && field.name === 'Trips' && !loggedUser.roles.includes('collaborator')"
                :key="index"
              />

              <FutureTrips
                v-if="field.checked && field.name === 'Trips' && !loggedUser.roles.includes('collaborator')"
                :key="index"
              />

              <Publications
                v-if="field.checked && field.name === 'Publications' && hasPermission('view publications')"
                :key="index"
                :user="user"
              />

              <Grants
                v-if="field.checked && field.name === 'Grants' && hasPermission('view grants')"
                :key="index"
                :user="user"
              />
            </template>
          </div>

          <Traces
            v-if="loggedUser.roles.includes('super-admin')"
          />
          <ItemInfoSheet>
            <template #info-sheet-title>
              Read notification
            </template>
            <template #info-sheet>
              <div class="offcanvas-body">
                <div class="mb-1 offcanvas-body--view--title">
                  <p>Send<br>
                    <strong>{{ active.date }}</strong></p>
                </div>
                <hr>
                <div class="mb-1 offcanvas-body--view--title">
                  <p>Subject<br>
                    <strong>{{ active.title }}</strong></p>
                </div>
                <div class="mb-1">
                  <p>Message<br>
                    <strong v-html="active.message" />
                  </p>
                </div>
                <div
                  v-if="active.files && active.files.length > 0"
                  class="mb-1"
                >
                  <p>Attached file(s)<br>
                    <template v-for="file in active.files">
                      <a
                        :key="file.id"
                        :href="file.url"
                        title="open file"
                        target="_blank"
                      >
                        <i data-feather="eye" /> {{ file.name }}
                      </a>
                      <br>
                    </template>
                  </p>
                </div>
              </div>
              <div class="offcanvas-footer mt-auto">
                <a
                  title="apply actions"
                  class="btn btn-dark mb-1 d-grid w-100"
                  @click="$store.dispatch('modals/toggleInfoItemSheet', false)"
                >Close</a>
              </div>
            </template>
          </ItemInfoSheet>
        </div>
        <ShowFormData
          v-if="user.enter_convo_id"
          :selected="{user_id: user.id, convo_id: user.enter_convo_id}"
        />
        <TableColumns :show-visibility="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { BCollapse, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import CalendarAndAlarms from './components/CalendarAndAlarms.vue'
import Traces from './components/Traces.vue'
import ResearcherNow from './components/ResearcherNow.vue'
import Tasks from './components/Tasks.vue'
import Timeline from './components/Timeline.vue'
import ShowFormData from '../../admin/academy-call/components/ShowFormData.vue'
import Trips from './components/Trips.vue'
import FutureTrips from './components/FutureTrips.vue'
import ShortBio from './components/ShortBio.vue'
import ContactInformation from './components/ContactInformation.vue'
import Publications from './components/Publications.vue'
import Grants from './components/Grants.vue'
import GeneralNotifications from '../../admin/dashboard/components/GeneralNotifications.vue'
import OtherNotifications from '../../admin/dashboard/components/OtherNotifications.vue'
import OSHNotifications from '../../admin/dashboard/components/OSHNotifications.vue'
import PendingAuthNotifications from '../../admin/dashboard/components/PendingAuthNotifications.vue'
import TableColumns from '../../partials/offcanvas/TableColumns.vue'

export default {
  components: {
    GeneralNotifications,
    OtherNotifications,
    OSHNotifications,
    PendingAuthNotifications,
    BDropdown,
    BDropdownItem,
    CalendarAndAlarms,
    Traces,
    Tasks,
    Timeline,
    ShowFormData,
    Trips,
    FutureTrips,
    ItemInfoSheet,
    ShortBio,
    ContactInformation,
    Publications,
    Grants,
    TableColumns,
    ResearcherNow,
  },
  data() {
    return {
      userId: this.$route.params.id,
      openEdit: false,
      loading: true,
      yearBetween: {},
      icon_path: '',
      hide: true,
      active: {},
      defaultFields: [
        {
          name: 'Short biography',
          checked: true,
          order: 1,
          visibility: true,
        },
        {
          name: 'Contact information',
          checked: true,
          order: 2,
        },
        {
          name: 'Publications',
          checked: true,
          order: 3,
        },
        {
          name: 'Grants',
          checked: true,
          order: 4,
        },
        {
          name: 'General notifications',
          checked: true,
          order: 5,
        },
        {
          name: 'Notifications pending of authorization',
          checked: true,
          order: 6,
        },
        {
          name: 'Other notifications',
          checked: true,
          order: 7,
        },
        {
          name: 'OSH Notifications',
          checked: true,
          order: 7,
        },
        {
          name: 'Calendar',
          checked: true,
          order: 8,
        },
        {
          name: 'Trips',
          checked: true,
          order: 9,
        },
        {
          name: 'Timeline',
          checked: true,
          order: 10,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      // user: 'auth/researcher',
      user: 'users/user',
      loggedUser: 'auth/admin',
      alerts: 'alerts/alerts',
      alertLoading: 'alerts/loading',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    await this.reloadDashboard()

    if (this.user.enter_convo_id) {
      await this.$store.dispatch('convo/fetchSpecific', this.user.enter_convo_id)
    }

    await this.$store.dispatch('modals/fetchUserFields', 'dashboard_researcher')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'dashboard_researcher',
      })
    }
  },
  methods: {
    viewCV() {
      if (this.user.CVFile.length > 0) {
        window.open(this.user.CVFile.length > 0 ? this.user.CVFile[0].url : null)
      }
    },
    showEvaluation() {
      // WIP until we import the senior calls
      // if (this.user.enter_convo_id) {
      //   this.$store.dispatch('modals/toggleFormData', true)
      // }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.$toastr.success('', 'Copied to clipboard')
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    async handleFileChange(event) {
      const file = event.target.files[0]
      if (file || this.scientificPersonalInfo.avatar.length > 0) {
        this.loading = true
        if (file) {
          file.weight = (file.size / 1024 / 1024).toFixed(2)
          const newAvatar = {
            name: file.name,
            original_name: file.name,
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            file,
          }
          const userAvatar = {
            avatar: newAvatar,
            user_id: this.user.id,
          }
          await this.$store.dispatch('users/uploadAvatar', userAvatar)
          this.newAvatar = []
          this.reloadDashboard()
        }
      }
    },
    async toggleAllSections(open) {
      const fields = this.fields.map(e => {
        e.visibility = open
        return e
      })

      await this.$store.dispatch('modals/fetchFields', {
        fields,
        table: 'dashboard_researcher',
        dont_refresh: true,
      })
    },
    async reloadDashboard() {
      if (this.userId && this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('users/fetchUser', this.userId)
      } else if (this.$route.query.prefilterUser && this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('users/fetchUser', this.$route.query.prefilterUser)
      } else {
        await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
      }

      this.icon_path = this.user ? this.user.avatarUrl : ''

      if (Array.isArray(this.user.areas)) {
        [this.user.areas] = this.user.areas
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    showAlert(alert) {
      this.active = alert
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
    openTableSettings() {
      this.$store.dispatch('modals/toggleTableSettings', true)
    },
  },
}
</script>
