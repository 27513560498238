<template>
  <div class="col-12">
    <div class="card card--profile-tasks">
      <div class="card-body">
        <div
          class="row"
        >
          <div class="col">
            <div
              v-b-toggle="`collapseTimeline`"
              class="clearfix"
            >
              <h3 class="float-start">
                <a data-action="collapse"><i
                  data-feather="activity"
                  class="w-icon-24"
                /> Timeline</a>
              </h3>
              <span class="float-end">
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="View all timeline"
                ><a
                  href="#"
                  class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                ><i data-feather="eye" /></a></span>
              </span>
            </div>
            <b-collapse
              :id="`collapseTimeline`"
              :visible="fields.find(e => e.name == 'Timeline').visibility"
            >
              <hr>
              <div class="table-responsive-sm">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Hour</th>
                      <th>Source</th>
                      <th class="text-end">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="time in timeline"
                      :key="time.id"
                    >
                      <td>{{ time.date }}</td>
                      <td>{{ time.hour }}</td>
                      <td class="text-capitalize">
                        {{ time.event }} {{ time.subject }}
                      </td>
                      <td class="text-end">
                        <a
                          title="open file"
                          target="_blank"
                          @click="openTimeline(time)"
                        >
                          <i data-feather="eye" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <ItemInfoSheet :table-id="4372">
      <template #info-sheet-title>
        See timeline element
      </template>
      <template #info-sheet>
        <div
          v-if="selectedItem.id"
          class="offcanvas-body offcanvas-body--view"
        >
          <div class="mb-1">
            <h4>Date<br></h4>
            <strong>{{ selectedItem.date }}</strong>
          </div>
          <div class="mb-1">
            <h4>Hour<br></h4>
            <strong>{{ selectedItem.hour }}</strong>
          </div>
          <div class="mb-1">
            <h4>Source<br></h4>
            <strong class="text-capitalize"> {{ selectedItem.event }} {{ selectedItem.subject }}</strong>
          </div>
          <template v-if="selectedItem.properties.old">
            <div
              class="mb-1"
            >
              <h4>Old data<br></h4>
              <span v-html="prettyPrintObject(selectedItem.properties.old)" />
            </div>
            <div class="mb-1">
              <h4>New data<br></h4>
              <span v-html="prettyPrintObject(selectedItem.properties.attributes)" />
            </div>
          </template>
        </div>
      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import Vue from 'vue'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ItemInfoSheet,
    BCollapse,
  },
  data() {
    return {
      timeline: [],
      selectedItem: {},
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
    }),
  },
  mounted() {
    Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/get-timeline`).then(resp => {
      this.timeline = resp.data.data
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    })
  },
  methods: {
    openTimeline(data) {
      this.selectedItem = data
      this.$store.dispatch('modals/toggleTableClicked', 4372)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
    prettyPrintObject(obj) {
      let result = ''

      // Use Object.keys() to get only the object's own properties
      Object.keys(obj).forEach(key => {
        const label = key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
        const value = typeof obj[key] === 'string' ? `${obj[key]}` : obj[key]
        result += `<strong>${label}</strong>: ${value} <br>`
      })

      return result
    },
  },
}
</script>

<style>

</style>
