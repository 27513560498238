<template>
  <div class="card card--profile-tasks">
    <div
      v-b-toggle="`collapseGrants`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse"><i
          data-feather="dollar-sign"
          class="w-icon-24"
        /> <a data-action="collapse">Active grants <span class="badge bg-light-info">{{ itemsTotal }}</span></a></a></h3>
      <div class="heading-elements">
        <span
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Go to the dashboard grants"
        ><a
          :href="$router.resolve({name: 'researcher.grants.index', query: { actAs: $route.query.actAs }}).href"
          target="_blank"
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
        ><i data-feather="eye" /></a></span>
        <ul class="list-inline mb-0 disp-in ms-2">
          <li>
            <a data-action="collapse"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down"
            ><polyline points="6 9 12 15 18 9" /></svg></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseGrants`"
        :visible="fields.find(e => e.name == 'Grants').visibility"
      >
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="table-responsive"> <!-- mostramos al inicio sólo las activas -->
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Type of grant</th>
                      <th>Type of funding</th>
                      <th>Firm/Funding Agency</th>
                      <th>Programme</th>
                      <th>Tor/fn</th>
                      <th>People</th>
                      <th>Top</th>
                      <th>Ta</th>
                      <th>Tagi</th>
                      <th>Tagru</th>
                      <th>Begin date</th>
                      <th>End date</th>
                      <th class="text-end">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="grant in items"
                      :key="grant.id"
                    >
                      <td>{{ grant.position_table }}</td>
                      <td>{{ grant.type.name }}</td>
                      <td>{{ grant.funding_type.name }}</td>
                      <td>{{ grant.funding_agency_manual }}</td>
                      <td>{{ grant.programme_manual }}</td>
                      <td>{{ grant.title_table }}</td>
                      <td>{{ grant.personnels_yearly.length }}</td>
                      <td v-html="grant.personnels_yearly.map(el => el.custom_name).join(',<br>')" />
                      <td>{{ grant.total_amount }}</td>
                      <td>{{ grant.total_amount_institution }}</td>
                      <td>{{ grant.total_amount_group }}</td>
                      <td>{{ grant.begin_date }}</td>
                      <td>{{ grant.end_date }}</td>
                      <td
                        class="text-end"
                      >
                        <b-dropdown
                          variant="link"
                          no-caret
                          toggle-class="p-0"
                          right
                        >
                          <template #button-content>
                            <i data-feather="more-vertical" />
                          </template>
                          <div
                            class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                          >
                            <b-dropdown-item
                              v-if="loggedUser.roles.includes('super-admin')"
                              @click="$router.push({name: 'researcher.dashboard', params: { id: grant.user.id }})"
                            >
                              <i
                                class="me-50"
                                data-feather="user"
                              /> View researcher profile
                            </b-dropdown-item>
                            <div
                              v-if="loggedUser.roles.includes('super-admin')"
                              class="dropdown-divider"
                            />
                            <b-dropdown-item @click="$router.push({name: 'researcher.grants.edit', query: { tab: 1, actAs: $route.query.actAs }, params: { id: grant.id }})">
                              <i
                                class="me-50"
                                data-feather="edit-3"
                              /> Edit
                            </b-dropdown-item>
                            <b-dropdown-item
                              :href="$router.resolve({name: 'researcher.grants.edit', params: { id: grant.id }, query: { tab: 1, actAs: $route.query.actAs }}).href"
                              target="_blank"
                            >
                              <i data-feather="external-link" />
                              Open in new tab
                            </b-dropdown-item>
                            <b-dropdown-item @click="showDeleteModal(grant.id)">
                              <i
                                class="me-50"
                                data-feather="trash-2"
                              /> Delete <span class="float-end"><i data-feather="lock" /></span>
                            </b-dropdown-item>
                          </div>
                        </b-dropdown>
                        <a
                          href=""
                          class="btn btn-icon btn-light-secondary d-block d-sm-none"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasMenu"
                          aria-controls="offcanvasBottom"
                        ><i
                          data-feather="more-vertical"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BDropdown, BDropdownItem, BCollapse,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCollapse,
  },
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      items: 'grants/items',
      itemsTotal: 'grants/itemsTotal',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    await this.$store.dispatch('grants/filter', {
      researcher: { id: this.user.id },
      begin_date: `${new Date().getFullYear()}-01-01`,
      end_date: `${new Date().getFullYear()}-12-31`,
    })
  },
}
</script>
