<template>
  <!-- start publications -->
  <div class="card card--profile-tasks">
    <div
      v-b-toggle="`collapsePublications`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse"><i
          data-feather="book-open"
          class="w-icon-24"
        /> <a data-action="collapse">Publications of the current year <span class="badge bg-light-info">{{ publicationsTotal }}</span></a></a></h3>
      <div class="heading-elements">
        <span
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Go to my publications"
        ><a
          :href="$router.resolve({name: 'researcher.publications.index', query: { actAs: $route.query.actAs }}).href"
          target="_blank"
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
        ><i data-feather="eye" /></a></span>
        <ul class="list-inline mb-0 disp-in ms-2">
          <li>
            <a data-action="collapse"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down"
            ><polyline points="6 9 12 15 18 9" /></svg></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapsePublications`"
        :visible="fields.find(e => e.name == 'Publications').visibility"
      >
        <div class="card-body">
          <div class="row">
            <div class="col">

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Checked by ICREA</th>
                      <th class="text-end">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="publication in publications">
                      <tr :key="publication.id">
                        <td>{{ publication.publication_type.name }}</td>
                        <td>{{ publication.publication_status.name }}</td>
                        <td>
                          <div v-html="publication.checked_by_icrea_table" />
                        </td>
                        <td class="text-end">
                          <span
                            class="dis-ib"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Edit publication"
                          ><a
                            class="btn btn-icon rounded-circle btn-flat-dark"
                            :href="$router.resolve({name: 'researcher.publications.edit', params: { id: publication.id }, query: { actAs: $route.query.actAs }}).href"
                            target="_blank"
                          ><i data-feather="edit-3" /></a></span>
                        </td>
                      </tr>
                      <tr :key="publication.id">
                        <td
                          colspan="4"
                          class="double-border-bottom"
                        >
                          <div v-html="publication.full_reference" />
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
  <!-- end publications -->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      publications: 'publications/items',
      publicationsTotal: 'publications/itemsTotal',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    await this.$store.dispatch('publications/filter', {
      researcher: { id: this.user.id },
      start_year: new Date().getFullYear(),
      end_year: new Date().getFullYear(),
    })
  },
}
</script>
