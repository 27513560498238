<template>
  <div
    v-if="item && loggedUser.roles.includes('super-admin')"
    class="col-6"
  >
    <div class="researcher__now">
      <h5>
        <span class="badge bg-warning me-1">NOW</span>
        <a href=""><strong>{{ item.title }}:</strong>
          <span class="text-dark">{{ item.description }} {{ item.begin_date }} - {{ item.end_date }}</span>
        </a>
      </h5>
      <p
        v-if="item.trips && item.trips[0]"
        class="mb-0"
      >
        <small>in {{ item.trips[0].complete_address }} ({{ item.trips[0].country }}), from {{ item.trips[0].start_date }} to {{ item.trips[0].end_date }}</small>
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      item: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      user: 'users/user',
    }),
  },
  async mounted() {
    Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/is-out/${this.user.id}`).then(resp => {
      this.item = resp.data
    })
  },
}
</script>
