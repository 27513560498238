<template>
  <div
    v-if="auxItems && auxItems.length > 0"
    class="col-12"
  >
    <div class="card card--profile-tasks">
      <div
        v-b-toggle="`collapseFutureTrips`"
        class="card-header"
      >
        <h3 class="float-start">
          <i
            data-feather="globe"
            class="w-icon-24"
          /> Future trips<span
            v-if="!loading"
            class="badge bg-light-info"
          >{{ auxItems.length }}</span>
        </h3>
      </div>
      <b-collapse
        :id="`collapseFutureTrips`"
        :visible="fields.find(e => e.name == 'Trips').visibility"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="table-responsive"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th
                    v-if="loggedUser.roles.includes('super-admin')"
                  >Researcher</th>
                  <th>Type</th>
                  <th>Begin date</th>
                  <th>End date</th>
                  <th>Country</th>
                  <th>TSS</th>
                  <th>Insurance</th>
                  <th>Address</th>
                  <th>Institution</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in auxItems"
                  :key="index"
                >
                  <td v-if="loggedUser.roles.includes('super-admin')">
                    {{ item.user ? item.user.user_name : '--' }}
                  </td>
                  <td>{{ item.type ? item.type : '--' }}</td>
                  <td>{{ item.begin_date ? item.begin_date : '--' }}</td>
                  <td>{{ item.end_date ? item.end_date : '--' }}</td>
                  <td>{{ item.country ? item.country : '--' }}</td>
                  <td><span :class="`badge bg-light-${item.tss ? 'success' : 'danger'}`">{{ item.tss ? 'Yes' : 'No' }}</span></td>
                  <td><span :class="`badge bg-light-${item.insurance ? 'success' : 'danger'}`">{{ item.insurance ? 'Yes' : 'No' }}</span></td>
                  <td>{{ item.address ? item.address : '--' }}</td>
                  <td>{{ item.place ? item.place : '--' }}</td>
                  <td class="text-end">
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      right
                    >
                      <template #button-content>
                        <i data-feather="more-vertical" />
                      </template>
                      <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                        <b-dropdown-item @click="$router.push({name: item.route, params: { id: item.origenable_id }})">
                          <i
                            class="me-50"
                            data-feather="eye"
                          /> View activity
                        </b-dropdown-item>
                      <!-- <div class="dropdown-divider" /> -->
                      <!-- <b-dropdown-item>
                        <i
                          class="me-50"
                          data-feather="message-square"
                        /> Change request
                      </b-dropdown-item> -->
                      </div>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCollapse,
  },
  data() {
    return {
      userId: this.$route.params.id,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      auxItems: 'tssManagement/auxItems',
      loggedUser: 'auth/admin',
      user: 'users/user',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    this.reloadPage()
  },
  methods: {
    async reloadPage() {
      this.loading = true
      if (this.userId) {
        await this.$store.dispatch('tssManagement/auxFilter', { researcher: { id: this.userId }, future: true })
      } else if (this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('tssManagement/auxFilter', { future: true })
      } else {
        await this.$store.dispatch('tssManagement/auxFilter', { researcher: { id: this.loggedUser.id }, future: true })
      }
      this.loading = false
      // eslint-disable-next-line no-undef
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
}
</script>

<style>

</style>
